.logo {
    position: relative;
    padding-bottom: calc(var(--spacing) * 4);
    height: var(--appbar-height);
}

.logoImg {
    opacity: 0;
    transition: opacity calc(var(--transitions-duration-leavingScreen) * 1ms);
}

.sidebar {
    width: 85px;
    transition: width .2s !important;
    overflow: hidden;
}

.sidebar.sidebarOpen {
    width: 268px;
}

.sidebar.sidebarOpen .logoImg {
    position: relative;
    opacity: 1;
    transition: opacity .2s calc(var(--transitions-duration-leavingScreen) * 1ms);
}

.logoImgSmall {
    width: 2rem;
    opacity: 1;
    transition: opacity .2s calc(var(--transitions-duration-leavingScreen) * 1ms);
}
.sidebarOpen .logoImgSmall {
    position: absolute;
    opacity: 0;
    transition: opacity .1s;
}
