@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://use.typekit.net/uam7ffz.css);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.OverlayModal_container__EasAp {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background: #fff;
}

.OverlayModal_content__3OhZt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.OverlayModal_icon__6ORH7 {
    width: 5rem;
    height: 5rem
}
.CarouselSelect_root__18g9Y {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: solid 1px #ccc;
    border-radius: .25em;
}

.CarouselSelect_button__3Wprr {
    display: block;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
}

.CarouselSelect_icon__2RSWv {
    display: block;
    width: .75em;
}

.CarouselSelect_carousel__3LSWW {
    width: 10rem;
    border-left: solid 1px #ccc;
    border-right: solid 1px #ccc;
}

.CarouselSelect_carouselItem__3sLAp {
    width: 10rem;
    text-align: center;
    font-size: .9rem;
}

div.DashboardCustomer_overview__3ZhmS {
    display: flex;
    width: 100%;
    flex-direction: column;
}

div.DashboardCustomer_overviewGraph__3gANa {
    flex: 1 1;
}

div.DashboardCustomer_overviewStats__1_rTO {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
}

@media(min-width: 1024px) {
    div.DashboardCustomer_overview__3ZhmS {
        flex-direction: row;
    }

    div.DashboardCustomer_overviewStats__1_rTO {
        width: 33%;
    }
}

.WeighingTicketsOverviewChart_weighingTicketChart__2PS6z {
    font-size: 14px;
}

.WeighingTicketsOverviewChart_chartLabel__1fDwt {
    fill: rgba(0, 0, 0, 0.54);
    font-weight: 500;
    font-size: 16px;
}

/*.yAxis {*/
/*    font-size: 14px;*/
/*}*/

.VideoCover_videoCover__3txV4 {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.VideoCover_videoCover__3txV4 iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.Layout_layout__JjF5u {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    min-height: 100vh;
    background-color: #fafafa;
}

.Layout_appFrame__1SIpx {
    display: flex;
    flex-grow: 1;
    overflow: auto;
}

.Layout_appBar__2iPkn {
    justify-content: center;
    width: auto !important;
    height: var(--appbar-height) !important;
    left: calc(var(--sidebar-width) * 1px) !important;
    transition: left calc(var(--transitions-duration-leavingScreen) * 1ms) var(--transitions-easing-sharp) !important;
    background-color: #fff !important;
}

.Layout_appSidebarClosed__279Pc .Layout_appBar__2iPkn {
    left: calc(var(--sidebar-closedWidth) * 1px) !important;
}

.Layout_toolbar__13j-7 {
    padding-left: 24px;
}

.Layout_appSidebar__2mO2r {
    position: relative;
    height: 100%;
    border-radius: 0 !important;
    padding: calc(var(--spacing) * 4);
}
.Layout_appSidebarClosed__279Pc .Layout_appSidebar__2mO2r {
    padding: calc(var(--spacing) * 3);
}

.Layout_content__kGTSN {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(1420px + var(--spacing) * 10);
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    padding: calc(var(--spacing) * 5);
    margin-top: var(--appbar-height);
}

.Sidebar_logo__2U7I3 {
    position: relative;
    padding-bottom: calc(var(--spacing) * 4);
    height: var(--appbar-height);
}

.Sidebar_logoImg__2N2AN {
    opacity: 0;
    transition: opacity calc(var(--transitions-duration-leavingScreen) * 1ms);
}

.Sidebar_sidebar__sIH1E {
    width: 85px;
    transition: width .2s !important;
    overflow: hidden;
}

.Sidebar_sidebar__sIH1E.Sidebar_sidebarOpen__2P9DS {
    width: 268px;
}

.Sidebar_sidebar__sIH1E.Sidebar_sidebarOpen__2P9DS .Sidebar_logoImg__2N2AN {
    position: relative;
    opacity: 1;
    transition: opacity .2s calc(var(--transitions-duration-leavingScreen) * 1ms);
}

.Sidebar_logoImgSmall__26f5d {
    width: 2rem;
    opacity: 1;
    transition: opacity .2s calc(var(--transitions-duration-leavingScreen) * 1ms);
}
.Sidebar_sidebarOpen__2P9DS .Sidebar_logoImgSmall__26f5d {
    position: absolute;
    opacity: 0;
    transition: opacity .1s;
}

.RaakUserMenu_user__1uC6u {

}

.RaakUserMenu_userButton__1Lpl4 {
    text-transform: none;
    cursor: pointer;
}

.RaakUserMenu_userButtonLabel__3ovQ- {
    display: flex;
    flex-direction: column;
}

.RaakUserMenu_userName__1Qbkr {
    font-weight: 400;
    text-align: left;
}

.RaakUserMenu_companyTitle__2MHKe {
    font-weight: 700;
    font-size: .75rem;
    margin: 0;
    word-break: break-word;
    text-align: left;
}

.RaakUserMenu_avatar__1b8fO {
    width: 1.8rem !important;
    height: 1.8rem !important;
}

.RaakUserMenu_menuItem__3R4-q {
    color: rgba(0, 0, 0, 0.87) !important;
}
